import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import { Link } from 'react-router-dom';

import axios from 'axios';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [sent, setSent] = React.useState(undefined)

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const sendEmail = () => {

    let name = document.getElementById('name').value
    let email = document.getElementById('email').value
    let subject = document.getElementById('subject').value
    let message = document.getElementById('message').value
    setSent(false)
    axios({
      method: 'post',
      url: `https://pixelkaktusz.hu/form.php`,
      headers: { 'content-type': 'application/json' },
      data: {
        fname: name,
        email: email,
        subject: subject,
        message: message
      }
    })
      .then(result => {
        setSent(true)
      })
      .catch(error => {
        setSent(true)
      });
  }

  return (
    <section
      id="section-contact"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Kérdése van? Írjon nekünk!
            </h3>
          </div>
          <div className="cta-action">
            <Input id="name" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Az ön neve">
            </Input>
            <p></p>
            <Input id="email" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Az ön email címe">
            </Input>
            <p></p>
            <Input id="subject" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Tárgy">
            </Input>
            <p></p>
            <Input id="message" type="textarea" label="Subscribe" labelHidden hasIcon="right" placeholder="Üzenet">

            </Input>

            <p></p>
            {
              sent == undefined ?
                <Link to="#0" className="button button-primary button-wide-mobile button-sm" onClick={() => sendEmail()}>Küldés</Link>
                : <p className="text-sm mb-0">{sent ? 'Elküldve' : 'Küldés...'}
                </p>
            }

          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;